import React from "react";
import Getid from "./Getid";
import Footer from "./footer";
import Marquee from "react-fast-marquee";

const Homepage = () => {
  const handleClaimNowClick = () => {
    window.open("https://bit.ly/ggnowww", "_blank");
  };

  return (
    <div
      onClick={handleClaimNowClick}
      className="w-full md:h-[90vh] h-full bg-[#000000] items-center relative"
    >
       <Marquee autoFill className="bg-yellow-400 text-black p-2 fixed top-0 z-[45]">
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🚀 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🌟 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🔥 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🚀 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🌟 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🔥 Get 10% Discount on every refill
        </span>
      </Marquee>
      <div className="w-full h-full relative  flex justify-center items-center">
        <img
          src="/WhatsApp Image 2024-12-11 at 13.28.31_f8970d61.jpg"
          alt="Hotel and Resort Promo"
          className="max-w-full max-h-full object-contain"
        />
        {/* <button className="bg-red-500 px-4  top-80 left-[10vw] rounded-xl md:left-[38vw] py-2 text-xl uppercase text-white font-bold absolute md:top-56">join now</button> */}
      </div>

      <div className="pb-16">
        <Footer className="" handleClaimNowClick={handleClaimNowClick} />
      </div>
      <Getid handleClaimNowClick={handleClaimNowClick} />
    </div>
  );
};

export default Homepage;
